import { createApp } from 'vue'
import App from './App.vue'
import translations from '../translations';
import {createI18n} from 'vue-i18n';
import LanguageTag from 'rfc5646';

if (!window.localStorage.locale) {
  const availableLocales = Object.getOwnPropertyNames(translations).map(l => new LanguageTag(l));
  const preferredLocales = navigator.languages.map(l => new LanguageTag(l));

  for (const preferredLocale of preferredLocales) {
    if (window.localStorage.locale) break;
    for (const availableLocale of availableLocales) {
      if (availableLocale.suitableFor(preferredLocale)) {
        window.localStorage.locale = availableLocale.toString();
        console.log(
          'Automatically selected locale',
          window.localStorage.locale,
          'out of available',
          availableLocales.map(l => l.toString()),
          'with preferred locales',
          preferredLocales.map(l => l.toString())
        );
        break;
      }
    }
  }

  if (!window.localStorage.locale) {
    window.localStorage.locale = 'en';
  }
}

const i18n = createI18n({
  locale: window.localStorage.locale,
  fallbackLocale: 'en',
  messages: translations,
  warnHtmlInMessage: 'off'
});

createApp(App).use(i18n).mount('#app')
