<template>
  <div class="reveal" ref="modal" data-reveal>
    <slot></slot>
  </div>
</template>

<script>
import Foundation from 'foundation-sites';
import $ from 'jquery';

export default {
  name: 'Modal',
  data() {
    return {
      modal: null,
    };
  },
  methods: {
    /**
     * Closes and destroys the modal.
     *
     * This is safe to call at any point.
     */
    destroy() {
      if (this.modal) {
        this.close();
        this.modal._destroy();
      }

      this.modal = null;
    },
    /**
     * Closes and destroys the modal.
     *
     * @deprecated use {@link #destroy()}
     */
    _destroy() {
      this.destroy();
    },
    /**
     * Re-initializes the modal.
     */
    reset() {
      this.destroy();
      this.modal = new Foundation.Reveal($(this.$refs.modal));
    },
    /**
     * Hides the modal.
     */
    close() {
      this.modal.close();
    },
    /**
     * Shows the modal.
     */
    open() {
      this.modal.open();
    },
  },
  mounted() {
    this.reset();
  },
  beforeDestroy() {
    this.destroy();
  },
};
</script>

<style scoped>

</style>
