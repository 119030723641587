import Question from '@/data/Question';

const normative_ids = [1, 6, 11, 18, 23, 26, 33, 37, 43, 47, 48, 53, 58, 62, 68, 72, 77]

const questions: Question[] = Array(80).fill(0).map((_, i) => new Question(i + 1, normative_ids.indexOf(i + 1) !== -1));

const nNormative = questions.map(q => q.normative ? 1 : 0).reduce((a, b) => a + b, 0 as number);
if (nNormative !== 17) {
  console.error('Expected 17 normative-style questions, got', nNormative);
}

export default questions;
