const translations = {
  'en': {
    ok: 'OK',
    title: 'Autism barcode',
    language: {
      name: 'English',
      select_language: 'Select language',
    },
    privacy: {
      header: 'Disclaimer & privacy statement',
      body:
`<p>
  This quiz is purely for fun, or as a conversation starter. While the questions and scoring system come from the 
  <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3134766/">RAADS-R scale</a>, which was 
  designed to be a tool to assist diagnosis, this quiz MUST NOT be used to draw conclusions. If you suspect you have
  <abbr title="autism spectrum disorder">ASD</abbr>, seek a professional diagnosis.
</p>
<p>
  Because the questions deal with subjects that can be considered personal, privacy is a concern. 
  <strong>This quiz does not store answers anywhere other than your device.</strong>
</p>
<p> 
  For the particularly paranoid: once the site has loaded you can disconnect 
  from the internet and still make full use of the page. Erasing your answers is possible using the 'Reset' button
  or through your browser's facilities for removing cookies and other site data, such as taking the quiz in 
  incognito mode. 
</p>
<p>
  The barcode you get at the end can be used to derive your exact answer to each question.
  Be careful who you share the results with.
</p>`,
    },
    action: {
      download_image: 'Download barcode',
      submit: 'Submit',
      change_language: 'Change language',
      review_privacy: 'Disclaimer & privacy',
      reset: 'Reset',
    },
    submit: {
      header: 'Submit your answers',
      notice_optional: 'Note: all questions are optional.',
      prior_diagnosis: {
        header: 'Do you have autism?',
        yes: 'Yes (professional diagnosis)',
        yes_explanation: 'Including Asperger\'s and PDD-NOS',
        maybe: 'Maybe',
        maybe_explanation: 'Currently undergoing diagnosis or self-suspected',
        no: 'No',
      },
      other_diagnoses: 'Do you have other, potentially related diagnoses (such as ADHD)?',
    },
    filename: 'barcode',
    header: {
      question: 'Some life experiences and personality characteristics that may apply to you',
      now_and_young: 'True now and when I was young',
      only_now: 'True only now',
      only_young: 'True only when I was younger than 16',
      never: 'Never true',
    },
    header_short: {
      question: 'Statement',
      now_and_young: 'Now and as a child',
      only_now: 'Only now',
      only_young: 'Only as a child',
      never: 'Never',
    },
    category: {
      socrel: 'Social',
      int: 'Interests',
      lang: 'Language',
      io: 'Sensory & Motor',
    },
    questions: {
      '1': 'I am a sympathetic person.',
      '2': 'I often use words and phrases from movies and television in conversations.',
      '3': 'I am often surprised when others tell me I have been rude.',
      '4': 'Sometimes I talk too loudly or too softly and I am not aware of it.',
      '5': 'I often don\'t know how to react in social situations.',
      '6': 'I can "put myself in other people\'s shoes".',
      '7': 'I have a hard time figuring out what some phrases mean, like "You are the apple of my eye".',
      '8': 'I only like to talk to people who share my special interests.',
      '9': 'I focus on details rather than the overall idea.',
      '10': 'I always notice how food feels in my mouth. This is more important than how it tastes.',
      '11': 'I miss my best friends or family when we are apart for a long time.',
      '12': 'Sometimes I offend others by saying what I am thinking, even if I don\'t mean to.',
      '13': 'I only like to think and talk about a few things that interest me.',
      '14': 'I would rather go out to eat in a restaurant by myself than with someone I know.',
      '15': 'I cannot imagine what it would be like to be someone else.',
      '16': 'I have been told that I am clumsy or uncoordinated.',
      '17': 'Others consider me odd or different.',
      '18': 'I understand when friends need to be comforted.',
      '19': 'I am very sensitive to the way clothes feel when I touch them. How they feel is more important than how they look.',
      '20': 'I like to copy the ways certain people speak and act. It helps me appear more normal.',
      '21': 'It can be very intimidating for me to talk to more than one person at a time.',
      '22': 'I have to "act normal" to please other people and make them like me.',
      '23': 'Meeting people is usually easy for me.',
      '24': 'I get highly confused when someone interrupts me when I am talking about something I am very interested in.',
      '25': 'It is difficult for me to understand how other people are feeling when we are talking.',
      '26': 'I like having a conversation with several people for instance around a dinner table, at school or at work.',
      '27': 'I take things too literally, so I often miss what people are trying to say.',
      '28': 'It is very difficult for me to understand when people are embarrassed or jealous.',
      '29': 'Some ordinary textures that do not bother others feel very offensive when they touch my skin.',
      '30': 'I get extremely upset when the way I like to do things is suddenly changed.',
      '31': 'I have never wanted or needed what other people call an "intimate relationship".',
      '32': 'It is difficult for me to start and stop a conversation. I need to keep going until I am finished.',
      '33': 'I speak with a normal rhythm.',
      '34': 'The same sound, color or texture can suddenly change from very sensitive to very dull.',
      '35': 'The phrase "I\'ve got you under my skin" makes me very uncomfortable.',
      '36': 'Sometimes the sound of a word or a high pitched noise can be painful to my ears.',
      '37': 'I am an understanding type of person.',
      '38': 'I do not connect with characters in movies and cannot feel what they feel.',
      '39': 'I cannot tell when someone is flirting with me.',
      '40': 'I can see in my mind in exact detail things that I am interested in.',
      '41': 'I keep lists of things that interest me, even when they have no practical use (for example sports statistics, train schedules, calendar dates, historical facts and dates).',
      '42': 'When I feel overwhelmed by my senses, I have to isolate myself to shut them down.',
      '43': 'I like to talk things over with my friends.',
      '44': 'I cannot tell if someone is interested or bored with what I am saying.',
      '45': 'It can be very hard to read someone\'s face, hand and body movements when they are talking.',
      '46': 'The same thing (like clothes or temperatures) can feel very different to me at different times.',
      '47': 'I feel very comfortable with dating or being in social situations with others.',
      '48': 'I try to be as helpful as I can when other people tell me their personal problems.',
      '49': 'I have been told that I have an unusual voice (for example flat, monotone, childish or high pitched).',
      '50': 'Sometimes a thought or subject gets stuck in my mind and I have to talk about it even if no-one is interested.',
      '51': 'I do certain things with my hands over and over again (like flapping, twirling sticks or strings, waving things by my eyes).',
      '52': 'I have never been interested in what most of the people I know consider interesting.',
      '53': 'I am considered a compassionate type of person.',
      '54': 'I get along with other people by following a set of specific rules that help me look normal.',
      '55': 'It is very difficult for me to work or function in groups.',
      '56': 'When I am talking to someone it is hard to change the subject. If the other person does so, I can get very upset or confused.',
      '57': 'Sometimes I cover my ears to block out painful noises (like vacuum cleaners or people talking too much or too loudly).',
      '58': 'I can chat and make small talk with people',
      '59': 'Sometimes things that should feel painful are not (for instance when I hurt myself or burn my hand on a stove).',
      '60': 'When talking to someone , I have a hard time telling when it is my turn to talk or listen.',
      '61': 'I am considered a loner by those who know me best.',
      '62': 'I usually speak in a normal tone.',
      '63': 'I like things to be exactly the same day after day and even small changes in my routines upset me.',
      '64': 'How to make friends and socialize is a mystery to me',
      '65': 'It calms me to spin around or to rock in a chair when I am feeling stressed.',
      '66': 'The phrase, "He wears his heart on his sleeve" does not make sense to me.',
      '67': 'If I am in a place where there are many smells,textures to feel, noises or bright lights I feel anxious or frightened.',
      '68': 'I can tell when someone says one thing but means something else.',
      '69': 'I like to be by myself as much as I can',
      '70': 'I keep my thoughts stacked in my memory like they are on filing cards, and I pick out the ones I need by looking through the stack and finding the right one (or other unique way).',
      '71': 'The same sound sometimes seems very loud or very soft, even though I know it has not changed.',
      '72': 'I enjoy spending time eating and talking with my family and friends.',
      '73': 'I can\'t tolerate things I dislike (like smells, textures, sounds or colors).',
      '74': 'I don\'t like to be hugged or held.',
      '75': 'When I go somewhere I have to follow a familiar routine or I can get very confused and upset.',
      '76': 'It is difficult to figure out what other people expect of me.',
      '77': 'I like to have close friends',
      '78': 'People tell me that I give too much detail.',
      '79': 'I am often told that I ask embarassing questions.',
      '80': 'I tend to point out other people\'s mistakes.',
    },
    footer: {
      source_code: 'Source code',
    }
  },
  'nl': {
    ok: 'OK',
    title: 'Autismestreepjescode',
    language: {
      name: 'Nederlands',
      select_language: 'Kies taal',
    },
    privacy: {
      header: 'Disclaimer & privacyverklaring',
      body:
`<p>
  Deze quiz is puur voor de leuk, of om tot een gesprek te komen. Hoewel de vragen en de manier waarop de score wordt 
  bepaald zijn gebaseerd op het <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3134766/">RAADS-R-systeem</a>, 
  wat bedoeld is om te helpen bij het diagnoseproces, moet je deze quiz NIET gebruiken om conclusies te trekken. 
  Als je denkt <abbr title="autismespectrumstoornis">ASS</abbr> te hebben, vraag het aan een psycholoog.
</p>
<p>
  Omdat de vragen over onderwerpen gaan die persoonlijk of gevoelig kunnen zijn, is privacy belangrijk. 
  <strong>Je antwoorden worden alleen op je huidige apparaat opgeslagen.</strong>
</p>
<p>
  Voor de paranoïden: zodra de site is geladen kan je offline gaan en de quiz werkt nog steeds volledig. Je kan je 
  antwoorden wissen via de 'Reset'-knop of door via je browser cookies en andere site-informatie te verwijderen, 
  zoals door de quiz te doen in incognito-modus. 
</p>
<p>
  De streepjescode die je op het eind krijgt kunnen worden gebruikt om precies te achterhalen 
  wat je antwoorden waren. Bedenk goed met wie je deze informatie wil delen.
</p>`,
    },
    action: {
      download_image: 'Download streepjescode',
      submit: 'Opsturen',
      change_language: 'Verander taal',
      review_privacy: 'Disclaimer & privacy',
      reset: 'Reset',
    },
    submit: {
      header: 'Antwoorden opsturen',
      notice_optional: 'N.B.: geen vraag is verplicht.',
      prior_diagnosis: {
        header: 'Heb je autisme?',
        yes: 'Ja (professioneel gediagnosticeerd)',
        yes_explanation: 'Inclusief Asperger\'s en PDD-NOS',
        maybe: 'Misschien',
        maybe_explanation: 'Op dit moment in een diagnose-traject of zelf een sterk vermoeden',
        no: 'Nee',
      },
      other_diagnoses: 'Heb je andere, mogelijk gerelateerde diagnoses (zoals ADD/ADHD)?',
    },
    filename: 'streepjescode',
    header: {
      question: 'Enkele ervaringen en persoonlijkheidskenmerken die op jou van toepassing zouden kunnen zijn',
      now_and_young: 'Waar nu en toen ik jong was',
      only_now: 'Waar alleen nu',
      only_young: 'Waar toen ik jonger dan 16 was',
      never: 'Nooit waar',
    },
    header_short: {
      question: 'Stelling',
      now_and_young: 'Nu en als kind',
      only_now: 'Alleen nu',
      only_young: 'Alleen als kind',
      never: 'Nooit',
    },
    category: {
      socrel: 'Sociaal',
      int: 'Interesses',
      lang: 'Taal',
      io: 'Zintuiglijk & Motoriek',
    },
    questions: {
      '1': 'Ik ben een sympathiek persoon.',
      '2': 'Ik gebruik in gesprekken vaak woorden en zinnen uit films en van televisie.',
      '3': 'Ik ben vaak verbaasd als anderen zeggen dat ik onbeleefd ben geweest.',
      '4': 'Soms praat ik te hard of te zacht zonder dat ik het doorheb.',
      '5': 'Ik weet vaak niet hoe ik moet reageren in sociale situaties.',
      '6': 'Ik kan "mezelf in andermans schoenen verplaatsen".',
      '7': 'Ik vind het moeilijk om te begrijpen wat sommige zinnen betekenen, zoals "jij bent mijn oogappel".',
      '8': 'Ik praat alleen graag met mensen die mijn speciale interesses delen.',
      '9': 'Ik focus me op details in plaats van op het algemene idee.',
      '10': 'Het valt me altijd op hoe eten in mijn mond aanvoelt. Dit is belangrijker dan hoe het smaakt.',
      '11': 'Ik mis mijn beste vrienden of familie als we voor een lange tijd uit elkaar zijn.',
      '12': 'Soms beledig ik anderen door te zeggen wat ik denk, ook al bedoel ik dat niet.',
      '13': 'Ik vind het alleen leuk om na te denken en te praten over een beperkt aantal dingen die me interesseren.',
      '14': 'Ik ga liever alleen uit eten in een restaurant dan met iemand die ik ken.',
      '15': 'Ik kan me niet voorstellen hoe het zou zijn om iemand anders te zijn.',
      '16': 'Er is mij verteld dat ik onhandig of ongecoördineerd ben.',
      '17': 'Anderen vinden mij vreemd of anders.',
      '18': 'Ik begrijp wanneer vrienden getroost moeten worden.',
      '19': 'Ik ben erg gevoelig voor hoe kleding aanvoelt als ik ze aanraak. Hoe ze voelen is belangrijker dan hoe ze eruit zien.',
      '20': 'Ik kopieer graag de manier waarop bepaalde mensen spreken en handelen. Het helpt me om er normaler uit te zien.',
      '21': 'Het kan voor mij erg intimiderend zijn om met meer dan één persoon tegelijk te praten.',
      '22': 'Ik moet "normaal doen" om andere mensen te plezieren en ervoor te zorgen dat ze mij leuk gaan vinden.',
      '23': 'Mensen ontmoeten vind ik meestal makkelijk.',
      '24': 'Ik raak erg in de war als iemand me onderbreekt als ik praat over iets waar ik erg geïnteresseerd in ben.',
      '25': 'Ik kan moeilijk begrijpen hoe andere mensen zich voelen als we praten.',
      '26': 'Ik vind het leuk om met meerdere mensen een gesprek te voeren, bijvoorbeeld rond een eettafel, op school of op het werk.',
      '27': 'Ik neem dingen te letterlijk, dus ik mis vaak wat mensen proberen te zeggen.',
      '28': 'Het is heel moeilijk voor mij om te begrijpen wanneer mensen zich schamen of jaloers zijn.',
      '29': 'Sommige gewone texturen die anderen niet storen, voelen erg aanstootgevend aan als ze mijn huid aanraken.',
      '30': 'Ik raak overstuur als de manier waarop ik dingen graag doe plotseling moet veranderen.',
      '31': 'Ik heb nooit gewild of nodig gehad wat andere mensen een \'intieme relatie\' noemen',
      '32': 'Ik vind het moeilijk om een gesprek te beginnen en te stoppen. Ik moet doorgaan tot ik klaar ben.',
      '33': 'Ik spreek met een normaal ritme.',
      '34': 'Dezelfde klank, kleur of textuur kan ineens veranderen van heel gevoelig of scherp naar heel dof of zwak.',
      '35': 'De zin "I\'ve got you under my skin" maakt me erg ongemakkelijk.',
      '36': 'Soms kan het geluid van een woord of een hoge toon pijnlijk zijn voor mijn oren.',
      '37': 'Ik ben een begripvol persoon.',
      '38': 'Ik verbind me niet met personages in films en kan niet voelen wat ze voelen.',
      '39': 'Ik kan niet zien wanneer iemand met me flirt.',
      '40': 'Ik kan in mijn hoofd tot in detail dingen zien waar ik in geïnteresseerd ben.',
      '41': 'Ik houd lijstjes bij van dingen die me interesseren, ook als ze geen praktisch nut hebben (bijvoorbeeld sportstatistieken, treinschema\'s, kalenderdata, historische feiten en data).',
      '42': 'Als ik me overweldigd voel door mijn zintuigen, moet ik mezelf isoleren om ze uit te schakelen.',
      '43': 'Ik praat graag met mijn vrienden.',
      '44': 'Ik kan niet zeggen of iemand geïnteresseerd is in wat ik zeg of niet.',
      '45': 'Het kan heel moeilijk zijn om iemands gezicht, hand en lichaamsbewegingen te lezen als ze praten.',
      '46': 'Hetzelfde (zoals kleding of temperaturen) kan voor mij op verschillende momenten heel anders aanvoelen.',
      '47': 'Ik voel me erg op mijn gemak met daten of in sociale situaties met anderen.',
      '48': 'Ik probeer zo behulpzaam mogelijk te zijn als andere mensen mij hun persoonlijke problemen vertellen.',
      '49': 'Er is mij verteld dat ik een ongewone stem heb (bijvoorbeeld vlak, monotoon, kinderachtig of hoog).',
      '50': 'Soms blijft een gedachte of onderwerp in mijn hoofd hangen en moet ik erover praten, zelfs als niemand geïnteresseerd is.',
      '51': 'Ik doe bepaalde dingen keer op keer met mijn handen (zoals fladderen, frummelen, dingen langs mijn ogen zwaaien).',
      '52': 'Ik ben nooit geïnteresseerd geweest in wat de meeste mensen die ik ken interessant vinden.',
      '53': 'Ik word beschouwd als een meelevend persoon.',
      '54': 'Ik kan goed opschieten met andere mensen door een reeks specifieke regels te volgen die me helpen er normaal uit te zien.',
      '55': 'Het is heel moeilijk voor mij om in groepen te werken of te functioneren.',
      '56': 'Als ik met iemand praat, is het moeilijk om van onderwerp te veranderen. Als de ander dat doet, kan ik erg overstuur of verward raken.',
      '57': 'Soms bedek ik mijn oren om pijnlijke geluiden te blokkeren (zoals stofzuigers of mensen die te veel of te hard praten).',
      '58': 'Ik kan een praatje maken met mensen',
      '59': 'Soms zijn dingen die pijnlijk zouden moeten voelen dat niet (bijvoorbeeld als ik mezelf pijn doe of mijn hand verbrand aan een kachel).',
      '60': 'Als ik met iemand praat, vind ik het moeilijk om te zeggen wanneer het mijn beurt is om te praten of te luisteren.',
      '61': 'Ik word als een eenling beschouwd door degenen die mij het beste kennen.',
      '62': 'Ik spreek meestal op een normale toon.',
      '63': 'Ik hou ervan dat alles elke dag hetzelfde is en zelfs kleine veranderingen in mijn routines maken me van streek.',
      '64': 'Vrienden maken en socializen is mij een raadsel',
      '65': 'Het kalmeert me om rond te draaien of in een stoel te schommelen als ik me gestresst voel.',
      '66': 'De zin "hij draagt zijn hart op zijn tong" klopt niet voor mij.',
      '67': 'Als ik op een plek ben waar veel geuren, texturen, geluiden of felle lichten zijn, voel ik me angstig of bang.',
      '68': 'Ik kan zien wanneer iemand het ene zegt, maar iets anders bedoelt.',
      '69': 'Ik ben graag zoveel mogelijk alleen',
      '70': 'Ik houd mijn gedachten gestapeld in mijn geheugen alsof ze op kaarten staan, en ik kies degene die ik nodig heb door de stapel te doorzoeken en de juiste te vinden (of een andere unieke manier).',
      '71': 'Hetzelfde geluid lijkt soms heel hard of heel zacht, ook al weet ik dat het niet veranderd is.',
      '72': 'Ik breng graag tijd door met eten en praten met mijn familie en vrienden.',
      '73': 'Ik kan niet tegen dingen die ik niet leuk vind (zoals geuren, texturen, geluiden of kleuren).',
      '74': 'Ik hou er niet van om geknuffeld of vastgehouden te worden.',
      '75': 'Als ik ergens heen ga, moet ik een vertrouwde routine volgen, anders kan ik erg in de war of overstuur raken.',
      '76': 'Het is moeilijk om erachter te komen wat andere mensen van mij verwachten.',
      '77': 'Ik heb graag goede vrienden',
      '78': 'Mensen zeggen me dat ik te veel details geef.',
      '79': 'Ik krijg vaak te horen dat ik gênante vragen stel.',
      '80': 'Ik heb de neiging om de fouten van anderen uit te wijzen.',
    },
    footer: {
      source_code: 'Broncode',
    }
  }
} as any;

export default translations;
