
import {defineComponent} from 'vue';
import questions from '@/data/questions';
import Question, {int, io, lang, socrel} from '@/data/Question';
import Modal from '@/components/Modal.vue';
import translations from '../translations';

export default defineComponent({
  name: 'App',
  components: {Modal},
  data() {
    return {
      questions,
      translations,

      locale: window.localStorage.locale,

      barcodeWidth: 1536,
      barcodeMarginX: 15,
      barcodeMarginY: 15,
      barHeightRatio: 0.7,
      fenceHeightRatio: 0.8,

      code: '',
      barcodeBlob: 'data:image/png,',

      codeButtonTextTimeout: undefined as number | undefined,
    }
  },

  watch: {
    locale(newV: string) {
      window.localStorage.locale = newV;
      this.$i18n.locale = newV;

      this.questions = [];
      this.questions = questions;

      document.documentElement.lang = newV;

      document.title = this.$t('title');
    }
  },

  computed: {
    barcodeHeight(): number {
      return this.barcodeWidth / 6;
    }
  },

  mounted() {
    document.title = this.$t('title');

    if (!window.localStorage.localeConfirmed) {
      (this.$refs.selectLanguageModal as any).open();
    } else if (!window.localStorage.getItem('privacyConfirmed')) {
      (this.$refs.privacyNoticeModal as any).open();
    }

    if (!window.localStorage.digit) {
      window.localStorage.digit = ((Math.random() * 800) | 0) % 4;
    }

    const params = new URLSearchParams(window.location.search);
    const debug = params.get('debug');
    let code = params.get('code');
    let encoded: bigint | undefined;

    if (code) {
      const [leadDigit, encodedStr, checkDigit] = code.split(' ');
      const lead = parseInt(leadDigit, 10);
      const check = parseInt(checkDigit, 10);
      encoded = BigInt(encodedStr);

      const calcedCheck = this.calcChecksum(encoded);
      if (check !== calcedCheck) {
        window.alert(`Bad checksum: expected ${check}, got ${calcedCheck}`);
      }

      const extractedLead = Number(encoded & 3n);
      if (lead !== extractedLead) {
        window.alert(`Bad lead: expected ${lead}, got ${extractedLead}`);
      }

      console.log(encoded.toString(2));
    }

    this.questions.forEach(q => {
      if (debug === 'random') {
        q.choice = (Math.random() * 4) | 0;
      } else if (debug === 'random-low') {
        q.choice = (Math.random() < 0.10) ? (Math.random() * 4) | 0 : (q.normative ? 3 : 0);
      } else if (debug === 'all') {
        q.choice = q.normative ? 0 : 3;
      } else if (debug === 'none') {
        q.choice = q.normative ? 3 : 0;
      } else if (encoded) {
        console.log(`d ${q.id}, ${(encoded >> BigInt(q.id * 2)) & 3n}`);
        q.choice = Number((encoded >> BigInt(q.id * 2)) & 3n);
      } else {
        const val = window.localStorage[`${q.id}`];
        if (val) q.choice = parseInt(val, 10);
      }
      window.localStorage.setItem(`${q.id}`, `${q.choice}`);
    });
    this.render();
  },

  methods: {
    update(question: Question, val: number) {
      question.choice = val;
      window.localStorage[`${question.id}`] = `${question.choice}`;
      this.render();
    },

    render() {
      const canvas = this.$refs.canvas as HTMLCanvasElement;
      const ctx = canvas.getContext('2d')!;

      // Background (and erase previous drawings)
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, this.barcodeWidth, this.barcodeHeight);

      ctx.fillStyle = 'black';
      ctx.lineWidth = 2;

      const usableWidth = this.barcodeWidth - 2 * this.barcodeMarginX;
      const usableHeight = this.barcodeHeight - 2 * this.barcodeMarginY;

      const fenceStartY = this.barcodeMarginY;
      const barStartY = this.barcodeMarginY;

      const fenceHeight = usableHeight * this.fenceHeightRatio;
      const fenceEndY = this.barcodeMarginY + fenceHeight;
      const barEndY = this.barcodeMarginY + usableHeight * this.barHeightRatio;

      ctx.font = `${((this.barcodeHeight - fenceHeight) * 0.6667 - 30)}px sans-serif`;

      const categories = [socrel, int, lang, io];
      const nBars = (this.questions.length + categories.length + 1);
      const barSpaceWidth = usableWidth / nBars;
      const maxBarWidth = 3./4. * barSpaceWidth;

      let x = this.barcodeMarginX;

      const subFenceWidth = barSpaceWidth / 5;

      ctx.lineWidth = subFenceWidth;
      ctx.beginPath();
      ctx.moveTo(this.barcodeMarginX + subFenceWidth, fenceStartY);
      ctx.lineTo(this.barcodeMarginX + subFenceWidth, fenceEndY);
      ctx.moveTo(this.barcodeMarginX + 3 * subFenceWidth, fenceStartY);
      ctx.lineTo(this.barcodeMarginX + 3 * subFenceWidth, fenceEndY);
      ctx.stroke();
      ctx.closePath();

      x += barSpaceWidth;

      let n = BigInt(window.localStorage.digit);

      for (const cat of categories) {
        // ctx.fillStyle = cat.color;
        // ctx.strokeStyle = cat.color;

        const catWidth = ((cat.ids.length) / nBars) * usableWidth;
        const catLabel = this.$t(`category.${cat.key}`);

        const textMetrics = ctx.measureText(catLabel);
        ctx.fillText(
            catLabel,
            x + catWidth / 2 - textMetrics.width / 2,
            fenceEndY + 15,
            catWidth
        );

        let i = 0;
        for (const id of cat.ids) {
          ++i;

          const bx = x + i * barSpaceWidth - barSpaceWidth / 2;

          const question = this.questions[id - 1];

          const choice = question.choice;
          console.log(choice, typeof choice);
          if (choice === -1 && question.normative) {
            n |= (3n << BigInt(question.id * 2));
          } else if (choice !== -1) {
            n |= (BigInt(choice) << BigInt(question.id * 2));
          }

          const choiceContrib = question.normative ? (3 - choice) : choice;
          if (choiceContrib > 0 && choiceContrib <= 3) {
            ctx.lineWidth = (choiceContrib / 3.) * maxBarWidth;
            ctx.beginPath();
            ctx.moveTo(bx - ctx.lineWidth / 8, barStartY);
            ctx.lineTo(bx - ctx.lineWidth / 8, barEndY);
            ctx.closePath();
            ctx.stroke();
          }
        }

        x += catWidth;

        ctx.lineWidth = subFenceWidth;
        ctx.beginPath();
        ctx.moveTo(x + subFenceWidth, fenceStartY);
        ctx.lineTo(x + subFenceWidth, fenceEndY);
        ctx.moveTo(x + 3 * subFenceWidth, fenceStartY);
        ctx.lineTo(x + 3 * subFenceWidth, fenceEndY);
        ctx.closePath();
        ctx.stroke();

        x += barSpaceWidth;
      }

      this.barcodeBlob = ctx.canvas.toDataURL('text/png');

      const check = this.calcChecksum(n);

      this.code = `${window.localStorage.digit} ${n} ${check}`;
    },

    confirmLocale() {
      window.localStorage.setItem('localeConfirmed', 'true');
      (this.$refs.selectLanguageModal as any).close();
      window.localStorage.removeItem('privacyConfirmed');
      (this.$refs.privacyNoticeModal as any).open();
      this.render();
    },

    confirmPrivacy() {
      window.localStorage.setItem('privacyConfirmed', 'true');
      (this.$refs.privacyNoticeModal as any).close();
    },

    reset() {
      window.localStorage.clear();
      window.location = window.location;
    },

    calcChecksum(n: bigint) {
      let acc = 0;
      let dat = n;
      while (dat) {
        acc += Number(dat % 10n);
        dat /= 10n;
      }
      return acc % 10;
    },
  }
});
