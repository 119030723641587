type CategoryKey = 'socrel' | 'lang' | 'int' | 'io';

export class Category {
  constructor(
    public readonly key: CategoryKey,
    public readonly name: string,
    public readonly ids: number[],
    public readonly color: string
  ) { }
}

const socrel = new Category('socrel', 'Social', [1, 6, 8, 11,
  14, 17, 18, 25, 37, 38, 3, 5, 12, 28, 39, 44, 45, 76, 79,
  80, 20, 21, 22, 23, 26, 31, 43, 47, 48, 53, 54, 55, 60, 61,
  64, 68, 69, 72, 77].sort(), 'red');

const int = new Category('int', 'Interests', [9, 13,
  24, 30, 32, 40, 41, 50, 52, 56, 63, 70, 75, 78].sort(), 'green');

const lang = new Category('lang', 'Language', [2, 7, 27, 35, 58, 66, 15].sort(), 'blue');

const io = new Category('io', 'Sensory & Motor', [10, 19, 4, 33, 34,
  36, 46, 71, 16, 29, 42, 49, 51, 57, 59, 62, 65, 67, 73, 74].sort(), 'yellow');

export {socrel, int, lang, io};

export default class Question {
  public readonly category: CategoryKey;

  public choice: number = -1;

  constructor(
    public readonly id: number,
    public readonly normative: boolean = false,
  ) {
    if (socrel.ids.indexOf(id) !== -1) {
      this.category = 'socrel';
    } else if (int.ids.indexOf(id) !== -1) {
      this.category = 'int';
    } else if (lang.ids.indexOf(id) !== -1) {
      this.category = 'lang';
    } else if (io.ids.indexOf(id) !== -1) {
      this.category = 'io';
    } else {
      throw new Error(`Bad id ${id}`);
    }
  }
}
